import React from 'react';
import { Helmet } from 'react-helmet';
import './App.css';
const App = () => {
    function handleClick(e){
      e.preventDefault();
      const logo = document.querySelector('.logo-container');
      const aboutSection = document.querySelector('#about-section');
      const heroTextContainer = document.querySelector('.hero-text-container');
      const aboutBg = document.querySelector('.about-bg');
      const heroLinks = document.querySelector('.hero-links');
      const heroLinksA = document.querySelector('.hero-links span');
      window.screen.width >= 767 ? logo.style.transform = 'scale(0.5) translateY(0)' : logo.style.transform = 'scale(0.8) translateY(0)';
      window.innerWidth > 767 ? logo.style.top = '-44px' : logo.style.top = '0px';
      heroTextContainer.style.opacity = 0;
      aboutSection.style.opacity = '1';
      aboutBg.style.opacity = 0.4;
      heroLinksA.style.opacity = 0;
      heroLinks.classList.add('hero-link-clicked')
    }

  return (
    <div className="App">
      <Helmet>
        <title>WASTELANDS FESTIVAL 2025</title>
        <meta name="title" content="Welcome to the Wastelands."/>
        <meta name="description" content="Wastelands is a new independent festival celebrating community, culture, and joy. Nestled snugly in the picturesque hills of the Scottish Borders, Wastelands strives to bring a fully unique and diverse line up." />
        <meta name="keywords" content="Music Festival, 2025, live music, concerts, events, DIY festival, Sound System culture, independent festival, local food, scottish festival, scotland festivals, scottish festivals, scottish skateparks, wild swimming, scotland, scottish borders, wastelands festival" />
      </Helmet>

      <div className="hero">
        <div className="hero-container">
          <div className="blob-container-right">
            <div className="blob blob_snowflake"></div>
            <div className="blob blob_doublestripe"></div>
            <div className="blob blob_arrowsquiggle"></div>
            <div className="blob blob_arrowbent"></div>
            <div className="blob blob_longsquiggle"></div>
            <div className="blob blob_mellowsquiggle"></div>
            <div className="blob blob_waves"></div>
            <div className="blob blob_zigzag"></div>
            {/* <div className="blob blob_circle"></div> */}
          </div>
          <div className="blob-container-left">
            <div className="blob blob_bigsquiggle"></div>
            <div className="blob blob_arrowstraight"></div>
            <div className="blob blob_dot"></div>
            <div className="blob blob_doublezag"></div>
            <div className="blob blob_bendlong"></div>
            <div className="blob blob_dubbend"></div>
            <div className="blob blob_circle_left"></div>
            {/* <div className="blob blob_"></div>
            <div className="blob blob_"></div> */}
          </div>
          
          <div className='logo-container'></div>
          <div className='hero-text-container'>
            <h2>June 2025</h2>
          </div>
          <div className="hero-links">
            <span onClick={handleClick}>Read More</span>
          </div>
        </div>
      </div>

      <div className="about-section" id="about-section">
        <div className='about-bg'></div>
        <div className="body-container">
          <div className='body-header'>
            <h2>Welcome to the Wastelands.</h2>
          </div>
          <div className='body-wrapper'>
            <div className='about-text-container'>
              <div className='about-text-header'>
                <h4>Wastelands is a new independent festival celebrating community, culture, and joy.</h4>
              </div>
              <div className='about-text-body'>
                <p>
                Nestled snugly in the picturesque hills of the Scottish Borders, 
                Wastelands strives to bring a fully unique and diverse line up, featuring stacks of Scottish and international talent.
                <br></br> <br></br> 
                With great local food vendors, breweries, markets, art installations, a skatepark and a loch for swimming - we are aiming to make this festival as inclusive, accessible, and diverse as possible, celebrating all cultures, all creeds, all over.
                </p>
              </div>
            </div>
          <div className='body-end'>
            <h2>See you in the fields.</h2>
          </div>
          </div>
        </div>

          <div className="links">
            <a href="#lineup">Lineup</a>
            <a href="#tickets">Tickets</a>
            <a href="#contact">Contact</a>
          </div>

        </div>
      </div>
  );
};

export default App;